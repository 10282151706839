<script>
import appConfig from "@/app.config";
import { VueFeedbackReaction } from "vue-feedback-reaction";
import Swal from "sweetalert2";
/**
 * Starter component
 */
export default {
  page: {
    title: "Rating",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { VueFeedbackReaction },
  data() {
    return {
      title: "Rating",
      soundurl : require('@/assets/mp3/notification_1.mp3'),
      errorSoundurl : require('@/assets/mp3/error.mp3'),
      feedback: "",
      items: [],
      labels: ["Very poor", "Poor", "Average", "Good", "Excellent"]
    };
  },

  methods: {
      sendFeedback () {
        
        // send feedback value to API
        var loader = this.$loading.show();
        this.$http
        .post("/feedback/postRestFeedback", {
          feedback: this.feedback,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
                var successNotification = new Audio(this.soundurl);
                successNotification.play();
                Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: res.data.original.msg,
                  showConfirmButton: false,
                  timer: 1500
                })      
            break;

            case 500:
                loader.hide();
                var errorNotif = new Audio(this.errorSoundurl);
                errorNotif.play();
                this.$toast.error(res.data.original.msg);
            break;
          
            default:
              break;
          }
        })
        .catch(() => {
          loader.hide();
          var errorNotif = new Audio(this.errorSoundurl);
          errorNotif.play();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
        // fire success msg

        // reset component
        this.feedback = "";
    }
  },
};
</script>
<template>
  <div class="ratingContainer">
    <!-- d -->
    <img src="@/assets/images/camplus_logo_white.png" alt="" height="160" />
    <br />
    <h1 class="question">Avez vous aimez la prestation ?</h1>
    <h1 class="question">هل اعجبتك الخدمة ؟</h1>
    <vue-feedback-reaction :labels="labels" @input="sendFeedback" class="feedback" v-model="feedback" />
    <img class="qr" src="@/assets/images/azimut_qr.png" alt="" height="120" />
    <img
      class="sh_logo"
      src="@/assets/images/sonatrach_logo.png"
      alt=""
      height="120"
    />
  </div>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" />
  </Layout> -->
</template>

<style scoped>

</style>

<style> 
.question {
  color: #efefef;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 45px;
}

.sh_logo {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding-left: 5px;
}

.qr {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
#app .ratingContainer{
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  transition: all 500ms linear 0s !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

#app .feedback {
  padding: 10px;
  text-align: center;
  display: inline-flex;
  background: #fff;
  border-radius: 12px;
  width: 600px !important;
}

.vue-reaction img {
  height: 70px !important;
}
</style>
